import React from 'react';
import { Link, Navigate, useParams } from 'react-router';

const newsArticles = [
    {
        id: 1,
        title: 'Обновление игры: Новые функции и захватывающие возможности!',
        description: `
				<img src="/images/post-img.webp" alt="alt" />
				<p>
					Сегодня разработчики выпустили обновление для популярной игры, которое
					принесет игрокам множество новых функций и улучшений. Одной из
					ключевых новинок стало расширение сюжетной линии, добавляющее
					несколько часов увлекательного геймплея. Также игроки смогут
					наслаждаться новыми персонажами и миссиями, которые добавят глубины
					игровому процессу.
				</p>
				<p>
					Визуальная составляющая игры была значительно улучшена: оптимизированы
					графические элементы, что позволяет достичь более высокой
					производительности на различных устройствах. Помимо этого, был
					переработан интерфейс, сделав его более интуитивным и удобным для
					игроков. Кроме того, обновление включает улучшения баланса игровых
					механик, которые учитывают пожелания сообщества, а также исправляет
					ряд багов, влияющих на игровой процесс. Разработчики призывают всех
					игроков загрузить обновление и испытать нововведения уже сегодня!
				</p>
		`,
        date: 'October 5, 2024'
    },
    {
        id: 2,
        title: 'Подготовка к открытию',
        description: 'Уважаемые игроки, на данный момент мы разрабатываем новые сервер...',
        date: 'October 2, 2024'
    }
];

const NewsDetails = () => {
    const { id } = useParams<{ id: string }>();
    const article = newsArticles.find((article) => article.id === parseInt(id || '0'));

    if (!article) {
        return <Navigate to="/news" />;
    }

    return (
		<main className="post-page">
			<div className="container">
				<div className="news-tabs">
					<Link to="/news/all" className="news-tab">Все</Link>
					<Link to="/news/news" className="news-tab active">Новости</Link>
					<Link to="/news/update" className="news-tab">Обновления</Link>
					<Link to="/news/event" className="news-tab">Акции</Link>
				</div>
				<h1 className="inner-h1">{article.title}</h1>
				<div className="news-slider-decoration">
					<img src="/images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
					<hr />
					<img src="/images/icon/news-decoration.svg" alt="decor" width="36" height="21" />
					<hr />
					<img src="/images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
				</div>
				<div dangerouslySetInnerHTML={{ __html: article.description }} />
			</div>
		</main>
    );
};

export default NewsDetails;