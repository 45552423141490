import React from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import config from '../config.tsx';

const FileDownload = () => {
	const { t } = useTranslation();

	return (
		<main className="files">
			<div className="container">
				<h1 className="inner-h1">{t('content.files.title')}</h1>
				<div className="files-container">
					<div className="decorations">
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
					</div>
					<div className="step">
						<img src="images/icon/step1.svg" alt="step" width="77" height="69" />
						<p dangerouslySetInnerHTML={{ __html: t('content.files.steps.step1') }} />
					</div>
					<div className="downloads">
						<Link to="#" className="download">
							<img src="images/icon/utorrent.svg" alt="utorrent" />
							<span className="download-name">Torrent</span>
							<span className="download-size">12.27 Gb</span>
						</Link>
						<Link to="#" className="download">
							<img src="images/icon/google-drive.svg" alt="google drive" />
							<span className="download-name">Google</span>
							<span className="download-size">12.27 Gb</span>
						</Link>
						<Link to="#" className="download">
							<img src="images/icon/yandex-disc.svg" alt="yandex" />
							<span className="download-name">Yandex</span>
							<span className="download-size">12.27 Gb</span>
						</Link>
						<Link to="#" className="download">
							<img src="images/icon/web.svg" alt="web" />
							<span className="download-name">Web</span>
							<span className="download-size">12.27 Gb</span>
						</Link>
					</div>
					<hr />
					<div className="step">
						<img src="images/icon/step2.svg" alt="step" width="77" height="69" />
						<p dangerouslySetInnerHTML={{ __html: t('content.files.steps.step2') }} />
					</div>
					<div className="downloads">
						<Link to="" className="download">
							<img src="images/icon/web-updater.svg" alt="updater" />
							<span className="download-name">Web Updater</span>
							<span className="download-size">12.27 Gb</span>
						</Link>
					</div>
					<hr />
					<div className="step">
						<img src="images/icon/step3.svg" alt="step" width="77" height="69" />
						<p>{t('content.files.steps.step3')}</p>
					</div>
					<Link to={config.AUTHORIZATION_URL + '/register'} className="cta-decoration">
						<span>{t('content.files.cta')}</span>
					</Link>
				</div>
			</div>
		</main>
	);
};

export default FileDownload;