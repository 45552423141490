import React from 'react';

import App from '../App.tsx';
import Home from '../pages/home.tsx';
import ServerInfo from '../pages/server-info.tsx';
import FileDownload from '../pages/file-download.tsx';
import News from '../components/news/news-list.tsx';
import NewsDetails from '../components/news/news-details.tsx';

const AppRoute = [
	{
		path: '/',
		element: <App />,
		children: [
			{ path: '', element: <Home /> },
			{ path: 'server-info/:id', element: <ServerInfo /> },
			{ path: 'server-info/:id/:type', element: <ServerInfo /> },
			{ path: 'download', element: <FileDownload /> },
			{ path: 'news', element: <News /> },
			{ path: 'news/:type', element: <News /> },
			{ path: 'news/:type/:id', element: <NewsDetails /> },
		],
	}
];

export default AppRoute;