import React from 'react';
import { Link } from 'react-router';
import config from '../config.tsx';

const servers = [
	{
		rate: "x2",
		name: "GENESIS",
		online: "15 000",
		icon: "images/icon/server1.svg",
		link: "/server-info/1"
	}
];

function Home() {
	return (
		<main>
			<section className="hero">
				<div className="container">
					<div className="hero-info">
						<h1>
							<span className="gold">открытие</span>
							<span className="silver">нового сервера</span>
						</h1>
						<div className="hero-description">
							Наша цель показать сообществу лучший сервер в жанре
							многопользовательских ролевых онлайн-играх
							<p className="start-date"><span>скоро</span></p>
						</div>
						<div className="hero-start">
							<Link to={config.AUTHORIZATION_URL + '/register'} className="cta-start">
								<img src="images/icon/cta-arrows.svg" alt="arrow" className="cta-start-arrows" />
								<span>Начать игру</span>
								<img src="images/icon/cta-arrows.svg" alt="arrow" className="cta-start-arrows" />
							</Link>
						</div>
					</div>
					<div className="girl-elf">
						<img src="images/hero-elf.webp" alt="" />
					</div>
				</div>
			</section>
			<section className="servers">
				<div className="servers-list container">
					{servers.map((server, index) => (
						<div className="server" key={index}>
							<div className="server-header">
								<img src={server.icon} alt={server.name} width="40" height="40" />
								<div className="server-info">
									<p className="server-rate">{server.rate}</p>
									<p className="server-name">{server.name}</p>
									<p className="server-online">
										Online: <span className="online">{server.online}</span>
									</p>
								</div>
							</div>
							<Link to={server.link} className="server-button">
								О сервере
								<img src="images/icon/arrow.svg" alt="arrow" width="9" height="9" />
							</Link>
						</div>
					))}
				</div>
			</section>
			<section className="news-index container">
				<div className="news-slider-decoration">
					<img src="images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
					<hr />
					<img src="images/icon/news-decoration.svg" alt="decor" width="36" height="21" />
					<hr />
					<img src="images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
				</div>
				<div className="news-slider">
					<Link to="" className="post" style={{ backgroundImage: `url(images/post1.webp)` }}>
						<p className="date">15.06.25</p>
						<div className="post-info">
							<h2>
								Установлено обновление Shine Maker!
								<img src="images/icon/arrow.svg" alt="arrow" width="12" height="12" />
							</h2>
							<p>
								Глобальное обновление Master Class — это не только долгожданный
								класс, но и жаркие битвы за новую территорию
							</p>
						</div>
					</Link>
				</div>
				<Link to="" className="all-news">
					Все новости
					<img src="images/icon/angle-arrow.svg" alt="arrow" height="20" />
				</Link>
			</section>
			<div className="middle-index">
				<section className="how-start container">
					<h2>Как начать играть</h2>
					<div className="steps">
						<div className="step">
							<img src="images/icon/step1.svg" alt="step" width="77" height="69" />
							<p>Регистрация<br />аккаунта</p>
						</div>

						<div className="step">
							<img src="images/icon/step2.svg" alt="step" width="77" height="69" />
							<p>Загрузка<br />файлов</p>
						</div>

						<div className="step">
							<img src="images/icon/step3.svg" alt="step" width="77" height="69" />
							<p>можно начинать<br />играть</p>
						</div>
					</div>
					<Link to={config.AUTHORIZATION_URL + '/register'} className="cta-decoration">
						<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
						<span>Начать играть</span>
						<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
					</Link>
				</section>
				<section className="advantages container">
					<div className="section-divider">
						<hr />
						<img src="images/icon/section-divider.svg" alt="decor" />
						<hr />
					</div>
					<h2>Преимущества</h2>
					<img src="images/icon/pluses-decoration.svg" alt="decor" width="215" height="14" />
					<div className="advantages-list">
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Тестовый заголовок преимуществ</h3>
								<div className="advantage-item-description">
									<p>
										Данный сервер создан игроками, которые очень любят Lineage 2!
									</p>
									<p>
										Играя на официальных серверах всех версий, мы всегда
										находили моменты, которые официальные разработчики по
										каким-то причинам упускали.
									</p>
								</div>
							</div>
						</div>
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Тестовый заголовок преимуществ</h3>
								<div className="advantage-item-description">
									<p>
										Данный сервер создан игроками, которые очень любят Lineage 2!
									</p>
									<p>
										Играя на официальных серверах всех версий, мы всегда
										находили моменты, которые официальные разработчики по
										каким-то причинам упускали.
									</p>
								</div>
							</div>
						</div>
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Тестовый заголовок преимуществ</h3>
								<div className="advantage-item-description">
									<p>
										Данный сервер создан игроками, которые очень любят Lineage 2!
									</p>
									<p>
										Играя на официальных серверах всех версий, мы всегда
										находили моменты, которые официальные разработчики по
										каким-то причинам упускали.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<section className="create-account">
				<div className="container">
					<div className="decorations">
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
						<img src="images/icon/corner-decoration.svg" alt="decor" width="68" height="68" className="decoration" />
					</div>
					<h2>
						<span>Создай персонажа</span><br />
						<span>и забери стартовый буст</span>
					</h2>
					<div className="create-account-container">
						<div className="create-account-gifts">
							<img src="images/chest.webp" alt="chest" className="chest" width="267" height="244" />
						</div>
						<div className="create-account-info">
							<p className="up-rate">Рейты для новичков повышены</p>
							<p className="bonus-description">
								А эти предметы помогут тебе быстрее адаптироваться в игре !
							</p>
							<div className="bonus-items">
								<img src="images/gift.webp" alt="bonus item" width="32" height="32" />
								<img src="images/gift.webp" alt="bonus item" width="32" height="32" />
								<img src="images/gift.webp" alt="bonus item" width="32" height="32" />
								<img src="images/gift.webp" alt="bonus item" width="32" height="32" />
								<img src="images/gift.webp" alt="bonus item" width="32" height="32" />
							</div>
							<Link to="" className="cta-decoration">
								<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
								<span>Забрать бонусы</span>
								<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
							</Link>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}

export default Home;