import React from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import config from '../../config.tsx';

function HomeFooter() {
	const { t } = useTranslation();

	return (
		<footer className="container">
			<div className="footer-decoration">
				<hr />
				<img src="/images/icon/footer-decoration.svg" alt={t('footer.logoAlt')} />
				<hr />
			</div>
			<div className="footer-navigation">
				<div className="left">
					<Link to="/" className="logo">
						<img src="/images/logo.svg" alt={t('footer.logoAlt')} />
						<span>genesis world</span>
					</Link>
					<div className="socials">
						<Link to="" className="social">
							<img src="/images/icon/tg-icon.svg" alt={t('footer.socials.telegramAlt')} />
						</Link>
						<Link to="" className="social">
							<img src="/images/icon/discord-icon.svg" alt={t('footer.socials.discordAlt')} />
						</Link>
						<Link to="" className="social">
							<img src="/images/icon/vk-icon.svg" alt={t('footer.socials.vkAlt')} />
						</Link>
					</div>
					<p className="copyright">{t('footer.copyright')}</p>
					<div className="policy">
						<Link to="" className="footer-link">{t('footer.privacyPolicy')}</Link>
						|
						<Link to="" className="footer-link">{t('footer.userAgreement')}</Link>
					</div>
				</div>
				<div className="right">
					<nav>
						<p className="footer-title">{t('footer.sections.main')}</p>
						<ul className="footer-menu">
							<li><Link to="/" className="footer-link">{t('footer.menu.home')}</Link></li>
							<li><Link to="/server-info/1" className="footer-link">{t('footer.menu.serverInfo')}</Link></li>
						</ul>
					</nav>
					<nav>
						<p className="footer-title">{t('footer.sections.account')}</p>
						<ul className="footer-menu">
							<li>
								<Link to={`${config.AUTHORIZATION_URL}/register`} className="footer-link">
									{t('footer.menu.register')}
								</Link>
							</li>
							<li>
								<Link to={`${config.AUTHORIZATION_URL}/login`} className="footer-link">
									{t('footer.menu.login')}
								</Link>
							</li>
							<li>
								<Link to={`${config.AUTHORIZATION_URL}/password-reset`} className="footer-link">
									{t('footer.menu.passwordReset')}
								</Link>
							</li>
							<li>
								<Link to={`${config.AUTHORIZATION_URL}/donation`} className="footer-link">
									{t('footer.menu.donation')}
								</Link>
							</li>
						</ul>
					</nav>
					<nav>
						<p className="footer-title">{t('footer.sections.useful')}</p>
						<ul className="footer-menu">
							<li><Link to="" className="footer-link">{t('footer.menu.rules')}</Link></li>
							<li><Link to={config.AUTHORIZATION_URL + '/support'} className="footer-link">{t('footer.menu.support')}</Link></li>
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	);
}

export default HomeFooter;