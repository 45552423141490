import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useRoutes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import AppRoute from './config/app-route.tsx';

import './i18n.tsx';

function App() {
	let element = useRoutes(AppRoute);
	return element;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
