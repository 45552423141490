import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import config from '../../config.tsx';

type SocialMediaLinks = {
	discord: string;
	telegram: string;
};

function HomeHeader() {
	const { i18n, t } = useTranslation();
	const [data, setData] = useState<SocialMediaLinks>();

	const changeLanguage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru');
	};

	useEffect(() => {
		fetch('/config.json')
			.then(response => response.json())
			.then(data => setData(data));
	}, []);

	return (
		<header>
			<div className="container">
				<Link to="/" className="logo">
					<img src="/images/logo.svg" alt="Genesis Lineage 2" />
					<span>genesis world</span>
				</Link>
				<ul className="menu">
					<li className="menu-item">
						<Link to="/" className="menu-link">{t('header.menu.home')}</Link>
					</li>
					<li className="menu-item">
						<Link to="/news" className="menu-link">{t('header.menu.news')}</Link>
					</li>
					<li className="menu-item">
						<Link to="/server-info/1" className="menu-link">{t('header.menu.serverInfo')}</Link>
					</li>
					<li className="menu-item">
						<Link to="/download" className="menu-link">{t('header.menu.downloads')}</Link>
					</li>
				</ul>

				<div className="language-login">
					<div className="socials">
						{data &&
							<>
								<Link to={data.telegram} className="social">
									<img src="/images/icon/tg-icon.svg" alt="telegram" />
								</Link>
								<Link to={data.discord} className="social">
									<img src="/images/icon/discord-icon.svg" alt="discord" />
								</Link>
							</>
						}
					</div>
					<Link to="#" onClick={changeLanguage} className="language">{i18n.language === 'ru' ? 'RU' : 'EN'}</Link>
					<Link to={config.AUTHORIZATION_URL} className="login-cta">
						<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								className="arrow"
								d="M7.60355 11.3964L11.4129 7.58712C11.6016 7.39839 11.6088 7.09472 11.4293 6.89723L7.61997 2.70697C7.31255 2.3688 6.75 2.58629 6.75 3.0433V4.75C6.75 5.02614 6.52614 5.25 6.25 5.25H2.25C1.69772 5.25 1.25 5.69772 1.25 6.25V8.25C1.25 8.80229 1.69772 9.25 2.25 9.25H6.25C6.52614 9.25 6.75 9.47386 6.75 9.75V11.0429C6.75 11.4883 7.28857 11.7114 7.60355 11.3964Z"
								fill="#FFBE45"
							/>
							<path
								className="border"
								d="M11.25 0.75H12.75C14.4069 0.75 15.75 2.09315 15.75 3.75V10.25C15.75 11.9069 14.4069 13.25 12.75 13.25H11.25M6.75 3.0433V4.75C6.75 5.02614 6.52614 5.25 6.25 5.25H2.25C1.69772 5.25 1.25 5.69772 1.25 6.25V8.25C1.25 8.80228 1.69772 9.25 2.25 9.25H6.25C6.52614 9.25 6.75 9.47386 6.75 9.75V11.0429C6.75 11.4883 7.28857 11.7114 7.60355 11.3964L11.4129 7.58712C11.6016 7.39839 11.6088 7.09472 11.4293 6.89723L7.61997 2.70697C7.31255 2.3688 6.75 2.58629 6.75 3.0433Z"
								stroke="#FFBE45"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						{t('header.menu.enter')}
					</Link>
				</div>
			</div>
		</header>
	);
}

export default HomeHeader;