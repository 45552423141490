import React from 'react';
import { Link } from 'react-router';

const navItems = [
	{
		"href": "/server-info/1/main",
		"icon": "ra-crossed-swords",
		"name": "Ethereal x1",
		"desc": "Основная информация"
	},
	{
		"href": "/server-info/1/verify",
		"icon": "ra-crossed-swords",
		"name": "Верификация Аккаунта",
		"desc": "Обитатель мира MultiWorld"
	},
	{
		"href": "/server-info/1/info",
		"icon": "ra-crossed-swords",
		"name": "Особенности",
		"desc": "дополнительное описание"
	},
	{
		"href": "/server-info/1/limit_steps",
		"icon": "ra-crossed-swords",
		"name": "Этапы развития",
		"desc": "концепция ограничений"
	},
	{
		"href": "/server-info/1/lcoin",
		"icon": "ra-crossed-swords",
		"name": "L-Монеты",
		"desc": "способы добычи"
	},
	{
		"href": "/server-info/1/store",
		"icon": "ra-crossed-swords",
		"name": "Магазин",
		"desc": "описание игрового магазина"
	},
	{
		"href": "/server-info/1/items",
		"icon": "ra-crossed-swords",
		"name": "Предметы",
		"desc": "список изменений"
	},
	{
		"href": "/server-info/1/balance",
		"icon": "ra-crossed-swords",
		"name": "Баланс Классов",
		"desc": "изменения умений"
	},
	{
		"href": "/server-info/1/valakas",
		"icon": "ra-crossed-swords",
		"name": "Битва с Валакасом",
		"desc": "Рейдовый Босс для Mid Кланов"
	},
	{
		"href": "/server-info/1/specialcraft",
		"icon": "ra-crossed-swords",
		"name": "Особое Создание",
		"desc": "создание книг и бижутерии"
	},
	{
		"href": "/server-info/1/ancientstore",
		"icon": "ra-crossed-swords",
		"name": "Древние Адены",
		"desc": "Магазин древних аден"
	},
	{
		"href": "/server-info/1/cursed_epic",
		"icon": "ra-crossed-swords",
		"name": "Проклятые Аксессуары",
		"desc": "Эпическая Бижутерия"
	},
	{
		"href": "/server-info/1/olympiad",
		"icon": "ra-crossed-swords",
		"name": "Великая олимпиада",
		"desc": "правила и магазин"
	},
	{
		"href": "/server-info/1/enchant_rates",
		"icon": "ra-crossed-swords",
		"name": "Шансы Модификации",
		"desc": "шансы модификации и синтеза предметов"
	},
	{
		"href": "/server-info/1/clans",
		"icon": "ra-crossed-swords",
		"name": "Кланы",
		"desc": "система кланов"
	},
	{
		"href": "/server-info/1/raid",
		"icon": "ra-crossed-swords",
		"name": "Рейдовые Боссы",
		"desc": "описание возрождений"
	},
	{
		"href": "/server-info/1/enchant_system",
		"icon": "ra-crossed-swords",
		"name": "Система модификации",
		"desc": "система синтеза имодификации"
	},
	{
		"href": "/server-info/1/offlinefarm",
		"icon": "ra-crossed-swords",
		"name": "Офлайн Охота",
		"desc": "Уникальный функционал"
	},
	{
		"href": "/server-info/1/telegram",
		"icon": "ra-crossed-swords",
		"name": "Telegram bot",
		"desc": "уведомления телеграм"
	},
	{
		"href": "/server-info/1/streamers",
		"icon": "ra-crossed-swords",
		"name": "Стримеры",
		"desc": "Поддержка медиа-партнеров"
	},
	{
		"href": "/server-info/1/dkp",
		"icon": "ra-crossed-swords",
		"name": "DKP",
		"desc": "Описание Системы"
	}
];

const ServerInfo = () => {
	return (
		<div className="page">
			<div className="content-area">
				<h2 className="page__title">О сервере <span>Back Bird</span></h2>
				<div className="page__content">
					<div className="about">
						<div className="about__nav">
							{navItems.map((item, index) => (
								<Link to={item.href} className="about__nav-item" key={index}>
									<div className="about__nav-item-icon">
										<i className={item.icon}></i>
									</div>
									<div className="about__nav-item-info">
										<div className="about__nav-item-name">{item.name}</div>
										<div className="about__nav-item-desc">{item.desc}</div>
									</div>
								</Link>
							))}
						</div>
						<div className="about__content">
							<div className="about__content-title"><h2>Основная информация</h2></div>
							<div className="about__content-info">
								<div className="text-area">
									<p>
										<span style={{ color: '#eac485' }}><strong>Открытие сервера Ethereal</strong></span>
										<span>- 18 Октября&nbsp;</span><span>2</span><span>0</span><span>:00 GMT </span><span>+3</span><br />
										<span style={{ color: '#eac485' }}><strong>Открытое Бета Тестирование</strong></span>
										<span>- 11 Октября&nbsp;</span><span>2</span><span>0</span><span>:00 GMT </span><span>+3</span><br />
										<span style={{ color: '#eac485' }}><strong>Обновление</strong></span>
										<span>&nbsp;-</span><span>&nbsp;High Elves: Elemental Master Reboot</span><span></span><br />
									</p>
									<ul>
										<li><span>Опыт<span>&nbsp;</span></span><span>/ SP&nbsp;<br />1-75 Lv. - x1<br />76-79 Lv. - x0.9<br />80-84 Lv. - x0.8<br />85+ Lv. - x0.7<br /></span><span></span></li>
										<li><span>Адена x</span><span>0</span><span>.5</span></li>
										<li><span>Автоматическое изучение навыков выключено</span><span> </span></li>
										<li><span>Максимальное количество клиентов на компьютер - 1<br /></span></li>
										<li><span>Максимально разрешенное количество компьютеров для игрока - 2</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServerInfo;