import React from 'react';
import { Link, useParams } from 'react-router';

const newsArticles = [
	{
		id: 1,
		title: 'Открытие сервера х3',
		description: 'Всех игроков поздравляем с запуском нового сервера!',
		date: 'October 5, 2024'
	},
	{
		id: 2,
		title: 'Подготовка к открытию',
		description: 'Уважаемые игроки, на данный момент мы разрабатываем новые сервер...',
		date: 'October 2, 2024'
	}
];

const News = () => {
	const { type } = useParams<{ type: string }>();

	return (
		<main className="news">
			<div className="container">
				<div className="news-tabs">
					<Link to="/news/all" className={`news-tab ${type === 'all' ? 'active' : ''}`}>Все</Link>
					<Link to="/news/news" className={`news-tab ${type === 'news' ? 'active' : ''}`}>Новости</Link>
					<Link to="/news/update" className={`news-tab ${type === 'update' ? 'active' : ''}`}>Обновления</Link>
					<Link to="/news/event" className={`news-tab ${type === 'event' ? 'active' : ''}`}>Акции</Link>
				</div>
				<h1 className="inner-h1">Новости</h1>
				<div className="section-divider">
					<hr />
					<img src="/images/icon/section-divider.svg" alt="decor" />
					<hr />
				</div>
				<div className="news-old">
					{newsArticles.map((article) => (
						<div className="small-post">
							<img src="/images/post1.webp" alt="" />
							<div className="small-post-content">
								<p className="date">{article.date}</p>
								<h2>{article.title}</h2>
								<p className="description">{article.description}</p>
								<Link to={`/news/${type}/${article.id}`} className="cta-decoration">
									<img
										src="/images/icon/corner-decoration.svg"
										alt="decor"
										className="decor"
									/>
									<span>Подробнее</span>
									<img
										src="/images/icon/corner-decoration.svg"
										alt="decor"
										className="decor"
									/>
								</Link>
							</div>
						</div>
					))}
				</div>
			</div>
		</main>
	);
};

export default News;